class FormSlider {
  constructor({ $element, baseClass }) {
    this.$element = $element;
    this.baseClass = baseClass;

    this.$inner = this.$element.querySelector(`.js-${this.baseClass}__inner`);
    this.$forms = [...this.$element.querySelectorAll(`.${this.baseClass}__form`)];

    this._initEventHandlers();
    this._initialScrolling();
  }

  _initEventHandlers() {
    const $links = [...this.$element.querySelectorAll('a[href^="#"]')];

    $links.forEach(($link) => $link.addEventListener('click', () => {
      const hash = $link.getAttribute('href').replace('#', '');
      this._scrollTo(hash);
    }));
  }

  _scrollTo(hash) {
    if (!hash || !~['login', 'reset'].indexOf(hash)) {
      hash = 'login';
    }

    this.$forms.findIndex((form) => {
      const formHash = form.getAttribute('data-hash');
      this.$inner.classList.remove(`${this.baseClass}__inner--${formHash}`);

      const isActive = form.getAttribute('data-hash') === hash;
      form.classList.toggle(`${this.baseClass}__form--active`, isActive);
    });

    if (hash) {
      this.$inner.classList.add(`${this.baseClass}__inner--${hash}`);
    }
  }

  _initialScrolling() {
    const hash = window.location.hash.replace('#', '');
    setTimeout(() => this._scrollTo(hash), 1);
  }
}


function init() {
  const $elements = [...document.querySelectorAll('.js-formSlider')];

  $elements.forEach(($element) => {
    new FormSlider({ $element, baseClass: 'formSlider' });
  });
}

if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
