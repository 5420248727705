import $ from 'jquery';


let instance;

export default class Tracking {
  constructor() {
    if (!instance) {
      instance = this;

      this._initEventHandling();
    }

    return instance;
  }

  _initEventHandling() {
    const $externals = $('a[href^="//"],a[href^="http"]').not('[href*="' + window.location.hostname + '"]');
    $externals.on('click', event => {
      this._send({
        category: 'External Link',
        action: 'Click',
        label: $(event.target).attr('href')
      });
    });

    const $downloads = $('a[href$=".pdf"]');
    $downloads.on('click', event => {
      this._send({
        category: 'Download',
        action: 'Click',
        label: $(event.target).text().trim()
      });
    });

    const $messages = $('.js-flashMessages__item');
    $messages.each((index, message) => {
      const $message = $(message);
      this._send({
        category: $message.data('category'),
        action: $message.data('action'),
        label: $message.data('label')
      });
    });
  }

  _send({ category, action, label = null }) {
    /* eslint-disable-next-line */
    ga('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }
}
