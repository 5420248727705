import $ from 'jquery';

function init() {
  const $trigger = $('.js-menu__trigger');
  const $content = $('.js-headerBottom');

  const topClassName = 'headerTop__menuTrigger--menu';
  const bottomClassName = 'headerBottom--menu';

  $trigger.on('click', () => {
    $content.toggleClass(bottomClassName);
    $trigger.toggleClass(topClassName);
  });

  $content.on('click', (event) => {
    const innerClassName = 'js-menu__contentInner';

    const $target = $(event.target);
    const $parent = $target.closest(`.${innerClassName}`);

    if (!$parent.length && !$target.hasClass(innerClassName)) {
      $content.removeClass(bottomClassName);
      $trigger.removeClass(topClassName);
    }
  });
}

export { init };
