import debounce from 'lodash/debounce';


class BulletinList {
  constructor({ $element, baseClass }) {
    this.$element = $element;
    this.baseClass = baseClass;

    this.$input = this.$element.querySelector(`.js-${this.baseClass}__input input`);
    this.$items = [...this.$element.querySelectorAll(`.js-${this.baseClass}__item`)];

    this._initEventHandlers();
  }

  _initEventHandlers() {
    this.$input.addEventListener('change', this._filterItems.bind(this));
    this.$input.addEventListener('input', debounce(this._filterItems.bind(this), 250));
  }

  _filterItems() {
    const term = this.$input.value.toLowerCase();

    this.$items.forEach(($item) => {
      const content = $item.innerHTML.replace(/(<([^>]+)>)/ig, '').toLowerCase();
      const containsTerm = ~content.indexOf(term);

      $item.classList.toggle(`${this.baseClass}__item--hidden`, !containsTerm);
    });
  }
}


function init() {
  const $elements = [...document.querySelectorAll('.js-bulletinList')];

  $elements.forEach(($element) => {
    new BulletinList({ $element, baseClass: 'bulletinList' });
  });
}

if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
