import $ from 'jquery';

import { Overlay } from '../common/overlay';


function init() {
  const $trigger = $('.js-categoryOverlay__trigger');
  const $content = $('#productCategories');

  $trigger.on('click', () => {
    const overlay = new Overlay();
    overlay.show($content.html());
  });
}


export { init };
