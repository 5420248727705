import $ from "jquery";

function init() {
  $(document).on("click", ".js-privacyOverlayButton", function(event) {
    event.preventDefault();

    window.location.search = "cookies=true";
  });
}

export { init };
