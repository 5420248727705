import $ from 'jquery';


function init() {
  const $textAreas = $('.js-textarea');

  $textAreas.each(function() {
    const $textArea = $(this);

    if (!$textArea.data('initialized')) {
      $textArea.attr('data-initialized', true);

      const $text = $textArea.find('.textarea__field');


      $text.on('focus', () => {
        $textArea.addClass('textarea--focussed');
      });

      $text.on('input', () => {
        $text[0].style.height = $text[0].scrollHeight + 'px';
      });

      $text.on('change', () => {
        $textArea.toggleClass('textarea--hasContent', !!$text.val());
      });

      $textArea.toggleClass('textarea--hasContent', !!$text.val());

      $text.on('blur', () => {
        $textArea.removeClass('textarea--focussed');
        $textArea.addClass('textarea--touched');
      });
    }

  });
}

export { init };
