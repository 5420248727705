import $ from 'jquery';

// event declarations
const EVENTS = {
  DISABLE_SCROLLING: 'SCROLLING_DISABLE_SCROLLING',
  ENABLE_SCROLLING: 'SCROLLING_ENABLE_SCROLLING',
  SHOW_HEADER: 'SCROLLING_SHOW_HEADER'
};

const SCROLLING_THRESHOLD = 25;

const CLASSES = {
  SCROLLED: 'scrolled',
  SCROLLING_DISABLED: 'no-scrolling'
};

// -1: not scrolled, 0: no change, 1: scrolled
let getScrollState = (next, current) => {
  if (next - current < -SCROLLING_THRESHOLD || next < SCROLLING_THRESHOLD) {
    return -1;

  } else if (next - current > SCROLLING_THRESHOLD) {
    return 1;
  }

  return 0;
};

let hasScrolledClass = () => {
  return $('body').hasClass(CLASSES.SCROLLED);
};


function init() {

  // cached dom references
  let $body, lastScrollTop;

  $body = $('html, body');
  lastScrollTop = 0;

  // disable scrolling on body
  $(document).bind(EVENTS.DISABLE_SCROLLING, () => {
    $body.addClass(CLASSES.SCROLLING_DISABLED);
  });

  // enable scrolling on body
  $(document).bind(EVENTS.ENABLE_SCROLLING, () => {
    $body.removeClass(CLASSES.SCROLLING_DISABLED);
  });

  function updateScrollPosition() {
    let top, scrollState;

    top = $(window).scrollTop();
    scrollState = getScrollState(lastScrollTop, top);

    if (scrollState === 1 || top < SCROLLING_THRESHOLD) {
      $('body').removeClass(CLASSES.SCROLLED);

    } else if (scrollState === -1) {
      $('body').addClass(CLASSES.SCROLLED);
    }

    lastScrollTop = top;
  }

  // append class to body when scrolled past epsilon
  $(window).scroll(updateScrollPosition);
}


export { EVENTS, SCROLLING_THRESHOLD, init, getScrollState, hasScrolledClass };
