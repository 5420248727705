import $ from 'jquery';
import Packery from 'packery';
import debounce from 'lodash/debounce';


function updateTexts() {
  const $overlays = $('.js-tiles__tileOverlay');

  $overlays.each((index, overlay) => {
    const $overlay = $(overlay);
    const overlayHeight = $overlay.outerHeight();

    const $content = $overlay.find('.js-tiles__tileContent');
    const $text = $overlay.find('.js-tiles__tileText');

    if ($text.length) {
      let contentHeight = $content.outerHeight();

      if (!$text.data('text')) {
        $text.data('text', $text.text());
      } else {
        $text.text($text.data('text'));
      }

      if (contentHeight > overlayHeight) {
        $text.text($text.text() + ' …');

        while (contentHeight > overlayHeight) {
          let words = $text.text().split(' ');
          words = words.slice(0, words.length - 2);

          if (words.length > 0) {
            words.push('…');
          }

          $text.text(words.join(' '));
          contentHeight = $content.outerHeight();
        }
      }

    }
  });
}

function init() {

  $('.js-tiles').each(function() {
    const $tiles = $(this);

    setTimeout(() => {
      new Packery(this, {
        itemSelector: '.js-tiles__tile',
        gutter: 0,
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: '.tiles__tile--1x1'
      });

      setTimeout(() => {
        $tiles.addClass('tiles__tiles--initialized');
      }, 1);

    }, 100);

  });

  $(window).resize(debounce(updateTexts, 100));

  updateTexts();
}


export { init };
