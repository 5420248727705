import debounce from 'lodash/debounce';

class DocumentsList {
  constructor({ $element, baseClass }) {
    this.$element = $element;
    this.baseClass = baseClass;

    this.$input = this.$element.querySelector(`.js-${this.baseClass}__input input`);
    this.$checkBoxes = [...this.$element.querySelectorAll(`.js-${this.baseClass}__checkbox input`)];

    this.$documents = [...this.$element.querySelectorAll(`.js-${this.baseClass}__document`)];
    this.$files = [...this.$element.querySelectorAll(`.js-${this.baseClass}__documentFile`)];

    this._initEventHandlers();
    this._openInitial();
    this._updateDocumentsCounts();
  }

  _initEventHandlers() {
    this.$input.addEventListener('change', this._filterDocuments.bind(this));
    this.$input.addEventListener('input', debounce(this._filterDocuments.bind(this), 250));

    this.$checkBoxes.forEach(($checkbox) => {
      $checkbox.addEventListener('change', this._filterDocuments.bind(this));
    });

    this.$documents.forEach(($document) => {
      const $headline = $document.querySelector(`.js-${this.baseClass}__documentHeadline`);
      $headline.addEventListener('click', this._toggleDocument.bind(this));
    });
  }

  _filterDocuments() {
    const term = this.$input.value.toLowerCase();
    const mimes = this.$checkBoxes
      .filter(($checkbox) => {
        return $checkbox.checked;
      })
      .map(($checkbox) => {
        return $checkbox.value;
      });

    if (term || mimes.length) {
      this.$documents.forEach(($document) => {
        const content = $document.innerHTML.replace(/(<([^>]+)>)/ig, '').toLowerCase();
        const containsTerm = !!~content.indexOf(term);

        $document.classList.remove(`${this.baseClass}__document--show`);
        $document.classList.toggle(`${this.baseClass}__document--hidden`, !containsTerm);
      });

      this.$files.forEach(($file) => {
        const content = $file.innerHTML.replace(/(<([^>]+)>)/ig, '').toLowerCase();
        const containsTerm = !!~content.indexOf(term);

        const mime = $file.getAttribute('data-mime');
        const hasMime = !mimes.length || !!~mimes.indexOf(mime);

        const isHidden = !containsTerm || !hasMime;

        $file.classList.toggle(`${this.baseClass}__documentFile--hidden`, isHidden);

        if (!isHidden) {
          $file.parents(`.js-${this.baseClass}__document`).forEach(($document) => {
            $document.classList.add(`${this.baseClass}__document--show`);
          });
        }
      });

    } else {

      this.$documents.forEach(($document) => {
        $document.classList.remove(`${this.baseClass}__document--show`);
        $document.classList.remove(`${this.baseClass}__document--hidden`);
      });

      this.$files.forEach(($file) => {
        $file.classList.remove(`${this.baseClass}__documentFile--hidden`);
      });
    }

    this._updateDocumentsCounts();
  }

  _updateDocumentsCounts() {
    this.$documents.forEach(($document) => {
      const $childDocuments = [...$document.querySelectorAll(`.js-${this.baseClass}__documentFile:not(.${this.baseClass}__documentFile--hidden)`)];
      const $count = $document.querySelector(`.js-${this.baseClass}__documentHeadlineCount`);

      const count = $childDocuments.length;

      if (count === 0) {
        $document.classList.add(`${this.baseClass}__document--hidden`);
      } else {
        $count.innerHTML = `(${count})`;
      }
    });
  }

  _toggleDocument(event) {
    event.stopPropagation();

    const $headline = event.target;
    const $document = $headline.closest(`.js-${this.baseClass}__document`);

    $document.classList.toggle(`${this.baseClass}__document--open`);
  }

  _openInitial() {
    const id = window.location.hash.replace('#', '');

    const $documents = this.$documents.filter(($document) => {
      return $document.getAttribute('data-id') === id;
    });

    $documents.forEach(($document) => {
      $document.classList.add(`${this.baseClass}__document--open`);

      $document.parents(`.js-${this.baseClass}__document`).forEach(($parentDocument) => {
        $parentDocument.classList.add(`${this.baseClass}__document--open`);
      });
    });
  }
}


function init() {
  const $elements = [...document.querySelectorAll('.js-documentsList')];

  $elements.forEach(($element) => {
    new DocumentsList({ $element, baseClass: 'documentsList' });
  });
}

if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
