import $ from 'jquery';

import { scrollToContent } from './helpers';


const scrollToHash = (hash, instant = false) => {
  hash = hash ? hash : window.location.hash;

  if (hash !== '') {
    scrollToContent($(hash), instant);
  }
};

const preventAnchorJump = (event) => {
  event.preventDefault();

  const hash = $(event.currentTarget).attr('href');

  if (hash !== window.location.hash) {
    if ('pushState' in history) {
      history.pushState(null, null, hash);
    }
  }

  scrollToHash(hash);
};


const init = () => {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  $('a[href^="#"]:not([href="#"])').on('click', preventAnchorJump);
  $(window).on('hashchange', () => scrollToHash());

  scrollToHash(window.location.hash, true);
};


export { init };
