import "babel-polyfill";

import $ from "jquery";

import "./common/dom-polyfills";

import { init as initScrolling } from "./common/body-scrolling";
import { init as initHashScrolling } from "./common/hash-scrolling";
import { init as initHorizontalScrolling } from "./common/horizontal-scrolling";
import { init as initFormBackup } from "./common/form-backup";
import { init as initCaptcha } from "./common/captcha";

import { init as initHero } from "./content/hero";
import { init as initMenu } from "./content/menu";
import { init as initSearch } from "./content/search";
import { init as initCategoryOverlay } from "./content/category-overlay";
import { init as initAdhoc } from "./content/adhoc";
import { init as initFlashMessages } from "./content/flash-messages";
import { init as initPrivacyOverlay } from "./content/privacy-overlay";
import { init as initGallery } from "./content/gallery";
import { init as initDoccheck } from "./content/doccheck-overlay";
import { init as initTiles } from "./content/tiles";
import { init as initMap } from "./content/map";

import { init as initInput } from "./form/input";
import { init as initTextarea } from "./form/textarea";
import { init as initNewsletter } from "./form/newsletter";
import { init as initTraining } from "./form/training";

import Tracking from "./common/tracking";

import "../components/molecules/form/form-slider/index.js";
import "../components/organisms/bulletin-list/index.js";
import "../components/organisms/documents-list/index.js";
import "../components/organisms/exit-dialog/index.js";

$(document).ready(() => {
  initScrolling();
  initHashScrolling();
  initHorizontalScrolling();
  initFormBackup();

  initHero();
  initMenu();
  initSearch();
  initCategoryOverlay();
  initAdhoc();
  initFlashMessages();
  initPrivacyOverlay();
  initGallery();
  initDoccheck();
  initTiles();
  initMap();

  initInput();
  initTextarea();
  initNewsletter();
  initTraining();

  initCaptcha();

  new Tracking();
});
