import $ from 'jquery';

import { Overlay } from '../common/overlay';
import { init as initInput } from './input';
import { init as initFormBackup } from '../common/form-backup';


class Newsletter {
  constructor({ formData = [] }) {
    const $template = $('#newsletter');
    const $content = $($template.html());

    formData.forEach((entry) => {
      if (~entry.name.indexOf('[]')) {
        const $field = $content.find(`[name="${entry.name}"][value="${entry.value}"]`);
        $field.prop('checked', $field.val() === entry.value);
      } else {
        const $field = $content.find(`[name="${entry.name}"]`);
        $field.val(entry.value);
      }
    });

    const overlay = new Overlay({
      modifiers: ['dark']
    });
    overlay.show($content);

    initInput();
    initFormBackup();

    $('body').trigger('SETUP_CAPTCHA');
  }
}

class NewsletterEntry {
  constructor({ element }) {
    this.$element = $(element);

    this._initEventHandling();
  }

  _initEventHandling() {
    this.$element.on('submit', (event) => {
      event.preventDefault();

      new Newsletter({
        formData: this.$element.serializeArray()
      });
    });
  }
}


function init() {
  const $newsletterEntries = $('.js-newsletter-entry');
  $newsletterEntries.each((index, element) => {
    new NewsletterEntry({
      element
    });
  });

  $(document).on('click', '.js-newsletter-trigger', () => {
    new Newsletter({});
  });

  const hash = window.location.hash;

  if (hash === '#newsletter') {
    if (history.replaceState) {
      history.replaceState('', document.title, window.location.href.split('#')[0]);
    } else {
      window.location.hash = '';
    }

    new Newsletter({});
  }
}

export { init };
