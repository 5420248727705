import $ from 'jquery';


function init() {
  const $inputs = $('.js-input');

  $inputs.each(function() {
    const $input = $(this);

    if (!$input.data('initialized')) {
      $input.attr('data-initialized', true);

      const $text = $input.find('.input__field');


      $text.on('focus', () => {
        $input.addClass('input--focussed');
      });

      $text.on('change', () => {
        $input.toggleClass('input--hasContent', !!$text.val());
      });

      $text.on('blur', () => {
        $input.removeClass('input--focussed');
        $input.addClass('input--touched');
      });

      const interval = setInterval(() => {
        let isAutofilled = false;

        try {
          isAutofilled = $text.is(':-webkit-autofill');
        } catch (e) {
          // catch error
        }

        if (!!$text.val() || isAutofilled) {
          $input.addClass('input--hasContent');
          clearInterval(interval);
        }
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
      }, 2000);
    }

  });
}

export { init };
