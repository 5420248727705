import $ from 'jquery';


function scroll(event) {

  const target = event.currentTarget;

  if (target.scrollWidth > target.offsetWidth) {
    event.preventDefault();

    if (event.originalEvent.deltaY > 0) {
      target.scrollLeft += 32;
    } else {
      target.scrollLeft -= 32;
    }
  }
}

function init() {
  const $scrollables = $('.js-horizontalScrolling');

  $scrollables.each((index, element) => {
    const $scrollable = $(element);

    $scrollable.on('mousewheel wheel', scroll);
  });
}

export { init };
