import $ from 'jquery';


let index = 0;
let count = 3;


function scrollTo() {
  index = (count + index) % count;

  $('.js-gallery__images').css({
    transform: `translate3d(${index * -100 / 3}%, 0, 0)`
  });

  const $thumbnails = $('.js-gallery__thumbnail');
  $thumbnails.removeClass('productHeader__thumbnail--active');
  $thumbnails.eq(index).addClass('productHeader__thumbnail--active');
}

function init() {
  count = $('.js-gallery__images img').length;

  $('.js-gallery__previous').on('click', () => {
    index--;
    scrollTo();
  });

  $('.js-gallery__next').on('click', () => {
    index++;
    scrollTo();
  });

  const $thumbnails = $('.js-gallery__thumbnail');
  $thumbnails.on('click', function() {
    index = $thumbnails.index($(this));
    scrollTo();
  });
}


export { init };
