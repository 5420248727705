import './classlist.js';

Element && (function(ElementPrototype) {
  ElementPrototype.matches = ElementPrototype.matches ||
    ElementPrototype.matchesSelector ||
    ElementPrototype.webkitMatchesSelector ||
    ElementPrototype.msMatchesSelector ||
    function(selector) {
      const node = this;
      const nodes = (node.parentNode || node.document).querySelectorAll(selector);

      let i = -1;

      while (nodes[++i] && nodes[i] !== node);

      return !!nodes[i];
    };

  ElementPrototype.closest = ElementPrototype.closest || function(selector) {
    let el = this;

    while (el.matches && !el.matches(selector)) {
      el = el.parentNode;
    }

    return el.matches ? el : null;
  };

  ElementPrototype.parents = ElementPrototype.parents || function(selector) {
    let elem = this;

    const elements = [];
    const hasSelector = selector !== undefined;

    while (elem.parentElement !== null) {
      elem = elem.parentElement;

      if (elem.nodeType !== Node.ELEMENT_NODE) {
        continue;
      }

      if (!hasSelector || elem.matches(selector)) {
        elements.push(elem);
      }
    }

    return elements;
  };
}(Element.prototype));
