import $ from 'jquery';
import Cookies from 'cookies-js';

const COOKIE_KEY = 'ezag_adhoc';


function close($adhoc) {
  const id = $adhoc.data('id');

  $adhoc.remove();

  if (Cookies.enabled) {
    const cookie = JSON.parse(Cookies.get(COOKIE_KEY) || '[]');

    if (!~cookie.indexOf(id)) {
      cookie.push(id);

      Cookies.set(COOKIE_KEY, JSON.stringify(cookie));
    }
  }
}

function init() {
  $(document).on('click', '.js-adhoc .js-adhoc__close', function(event) {
    event.preventDefault();
    event.stopPropagation();

    const $adhoc = $(this).closest('.js-adhoc');
    close($adhoc);
  });

  $(document).on('click', '.js-adhoc', function() {
    const $adhoc = $(this);

    close($adhoc);
  });
}


export { init };
