import $ from 'jquery';

function init() {
  $('.js-categoryHero__toggle').on('click', (event) => {
    $(event.target).hide();

    $('.js-categoryHero__additional').show();
  });
}

export { init };
