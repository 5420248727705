import $ from 'jquery';


function init() {
  setTimeout(() => {
    const $messages = $('.js-flashMessages');
    $messages.addClass('flashMessages--visible');
  }, 1000);

  $(document).on('click', '.js-flashMessages .js-flashMessages__close', function(event) {
    event.preventDefault();
    event.stopPropagation();

    const $adhoc = $(this).closest('.js-flashMessages__item');
    $adhoc.remove();
  });
}


export { init };
