import $ from 'jquery';


function init() {
  const $googles = $('.google');

  $googles.each((index, element) => {
    const $google = $(element);

    const $button = $google.find('.js-google__button');

    $button.on('click', () => {
      $google.toggleClass('google--active');
    });
  });
}


export { init };
