import $ from "jquery";
import LocalStorage from "./local-storage";

// store form data by form key in localStorage
let updateBackup = ($form, $items, formKey = "global") => {
  let storage = new LocalStorage();

  if (storage) {
    let data = {};

    $items.each(function() {
      let $item = $(this);
      data[$item.attr("name")] = $item.val();
    });

    storage.setItem(formKey, data);
  }
};

// populate form with data form localStorage
let restoreFromBackup = ($form, $items, formKey = "global") => {
  let storage = new LocalStorage();

  if (storage) {
    let data = storage.getItem(formKey);

    if (data) {
      for (let itemKey in data) {
        if (data.hasOwnProperty(itemKey)) {
          $form.find('[name="' + itemKey + '"]').each(function() {
            let $item = $(this);

            if ($item.prop("tagName") === "SELECT") {
              if (data[itemKey]) {
                $item.val(data[itemKey]).trigger("change");
              }
            } else {
              if (!$item.val() && !!data[itemKey]) {
                $item.val(data[itemKey]).trigger("change");
              }
            }
          });
        }
      }
    }
  }
};

let init = () => {
  $(".js-formbackup").each(function() {
    let $form, $items;

    $form = $(this);

    if (!$form.data("initialized")) {
      $form.attr("data-initialized", true);

      $items = $form
        .find("input")
        .not('[autocomplete="off"]')
        .not('[data-backup="false"]');

      restoreFromBackup($form, $items);

      $items.on("change", () => {
        updateBackup($form, $items);
      });
    }
  });
};

export { init };
