import MemoryStorage from "./memory-storage";

let instance;

export default class LocalStorage {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.storage = this._isLocalStorageAvailable()
      ? window.localStorage
      : new MemoryStorage();

    return instance;
  }

  setItem(key, value) {
    if (typeof key === "undefined" || typeof value === "undefined") {
      return false;
    }

    this.storage.setItem(key, JSON.stringify(value));

    return true;
  }

  getItem(key) {
    if (typeof key === "undefined") {
      return false;
    }

    let value = this.storage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }

    return false;
  }

  hasItem(key) {
    if (typeof key === "undefined") {
      return false;
    }

    return this.storage.hasItem(key);
  }

  removeItem(key) {
    if (typeof key === "undefined") {
      return false;
    }

    if (this.storage.hasItem(key)) {
      this.storage.removeItem(key);
    }

    return true;
  }

  _isLocalStorageAvailable() {
    try {
      let storage = window.localStorage;

      storage.setItem("test", "test");
      storage.removeItem("test");

      return true;
    } catch (e) {
      return false;
    }
  }
}
