import $ from 'jquery';

class ExitDialog {
  constructor({ $element, baseClass, href }) {
    this.$element = $element;
    this.baseClass = baseClass;
    this.href = href;

    this.$close = [...this.$element.querySelectorAll(`.js-${this.baseClass}__close`)];

    this.$accept = this.$element.querySelector(`.js-${this.baseClass}__accept`);

    this.open();

    this._initEventHandlers();
  }

  open() {
    this.$element.classList.add(`${this.baseClass}--animate`);

    setTimeout(() => {
      this.$element.classList.add(`${this.baseClass}--visible`);
    });
  }

  close() {
    this.$element.classList.remove(`${this.baseClass}--visible`);
  }

  _initEventHandlers() {
    this.$close.forEach(($close) => {
      $close.addEventListener('click', this.close.bind(this));
    });

    this.$accept.addEventListener('click', () => {
      window.location.href = this.href;
    });
  }
}


function init() {
  const location = window.location.href;

  if (!!~location.indexOf('/portal/') && !location.endsWith('/portal/') && !location.endsWith('/portal')) {
    $('a').each((index, link) => {
      const $link = $(link);
      const linkHref = $link.attr('href');
      const target = $link.attr('target');

      if (linkHref.startsWith('http') && !~linkHref.indexOf('/portal/') && !linkHref.endsWith('download_file=1') && target !== '_blank') {
        $link.on('click', (event) => {
          const $target = event.currentTarget;
          const href = $target.getAttribute('href');

          event.preventDefault();

          const $element = document.getElementById('exit-dialog');
          new ExitDialog({
            $element,
            baseClass: 'ExitDialog',
            href
          });
        });
      }
    });
  }
}

if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
