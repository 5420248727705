import $ from 'jquery';

function init() {
  const $search = $('.js-search');
  const $trigger = $('.js-search__trigger');
  const $close = $('.js-search__close');

  const topClassName = 'headerTop__search--open';

  $trigger.on('click', () => {
    $search.toggleClass(topClassName);
  });

  $close.on('click', () => {
    $search.removeClass(topClassName);
  });

  $search.on('click', (event) => {
    const innerClassName = 'js-search__inner';

    const $target = $(event.target);
    const $parent = $target.closest(`.${innerClassName}`);

    if (!$parent.length && !$target.hasClass(innerClassName)) {
      $search.removeClass(topClassName);
    }
  });
}

export { init };
