import $ from 'jquery';
import debounce from 'lodash/debounce';


const OPTIONS = {
  sitekey: '6LeqLRMUAAAAAON21GKT3XJg-36BGjsDb-jVluTk',
  theme: 'light',
  size: 'normal'
};


function setupCaptcha() {
  $('.g-recaptcha').each((index, element) => {
    const $captcha = $(element);
    const bigParent = $captcha.width() > 310;
    const size = bigParent ? 'normal' : 'compact';

    $captcha.children().remove();

    const $clone = $captcha.clone();
    $captcha.replaceWith($clone);

    window.grecaptcha.render($clone[0], Object.assign({}, OPTIONS, { size }));

    $clone
      .removeClass('form__recaptcha--compact form__recaptcha--normal')
      .addClass(`form__recaptcha--${OPTIONS.size}`);
  });
}


function init() {
  if (window.grecaptcha && window.grecaptcha.render) {
    setupCaptcha();

    $(document).on('SETUP_CAPTCHA', () => {
      setupCaptcha();
    });

    $(window).on('resize', debounce(setupCaptcha, 500));

  } else {
    setTimeout(init, 500);
  }
}


export { init };
