import $ from "jquery";

import { EVENTS as SCROLLING_EVENTS } from "./body-scrolling";

const CSS_CLASSES = {
  SHOW: "overlay--visible",
  FADE_IN: "overlay--fade-in",
};

// singleton instance
let instance = null;

const svg =
  '<svg width="21" height="21" viewBox="18 17 21 21" xmlns="http://www.w3.org/2000/svg"><path fill="#FFF" fill-rule="evenodd" d="M39 19.1L36.9 17l-8.4 8.4-8.4-8.4-2.1 2.1 8.4 8.4-8.4 8.4 2.1 2.1 8.4-8.4 8.4 8.4 2.1-2.1-8.4-8.4"/></svg>';

// singleton
export class Overlay {
  // prepare dom, add events
  constructor({ modifiers, hideCallback } = {}) {
    // return single instance
    if (!instance) {
      instance = this;
    }

    let classes = ["overlay", "js-overlay"];

    if (modifiers) {
      for (const modifier of modifiers) {
        classes.push(`overlay--${modifier}`);
      }
    }

    if (hideCallback) {
      instance.hideCallback = hideCallback;
    }

    // add message container if not existing
    if (!$(".js-overlay").length) {
      $("body").append(
        $(`
        <div class="${classes.join(" ")}">
          <div class="overlay__center">
            <button class="overlay__close js-overlay__close">${svg}</button>
            <div class="overlay__content js-overlay__content"></div>
          </div>
        </div>
      `)
      );

      // store in attribute
      instance.$overlay = $(".js-overlay");
      instance.$content = instance.$overlay.find(".js-overlay__content");

      $(document).on("click", ".js-overlay__close", (event) => {
        event.stopPropagation();

        instance.hide();
      });

      $(document).on("click", ".js-overlay", (event) => {
        event.stopPropagation();

        if ($(event.target).parents(".js-overlay").length === 0) {
          instance.hide();
        }
      });
    } else {
      instance.$overlay.removeAttr("class").addClass(classes.join(" "));
    }

    return instance;
  }

  show(content = "") {
    this.$content.html(content);
    this.$overlay.addClass(CSS_CLASSES.SHOW);

    $(document).trigger(SCROLLING_EVENTS.DISABLE_SCROLLING);

    setTimeout(() => {
      this.$overlay.addClass(CSS_CLASSES.FADE_IN);
    }, 10);
  }

  hide() {
    this.$overlay.removeClass(CSS_CLASSES.FADE_IN);

    history.replaceState(
      "",
      document.title,
      window.location.href.split("#")[0]
    );

    $(document).trigger(SCROLLING_EVENTS.ENABLE_SCROLLING);

    setTimeout(() => {
      this.$overlay.removeClass(CSS_CLASSES.SHOW);
      this.$content.empty();

      if (this.hideCallback) {
        this.hideCallback();
      }
    }, 300);
  }
}
