let instance;

export default class MemoryStorage {

  constructor() {
    if (!instance) {
      instance = this;
    }

    this.storage = {};

    return instance;
  }

  setItem(key, value) {
    if (typeof key === 'undefined' || typeof value === 'undefined') {
      return false;
    }

    this.storage[key] = value;

    return true;
  }

  getItem(key) {
    if (typeof key === 'undefined') {
      return false;
    }

    return this.storage[key];
  }

  hasItem(key) {
    if (typeof key === 'undefined') {
      return false;
    }

    return typeof this.storage[key] !== 'undefined';
  }

  removeItem(key) {
    if (typeof key === 'undefined') {
      return false;
    }

    if (this.hasItem(key)) {
      delete this.storage[key];
    }

    return true;
  }
}
