import $ from "jquery";

import { Overlay } from "../common/overlay";
import { init as initInput } from "./input";
import { init as initTextarea } from "./textarea";
import { init as initFormBackup } from "../common/form-backup";

class Training {
  constructor({ option }) {
    const $template = $("#training_form");
    const $content = $($template.html());

    const $option = $content.find(`[value="${option}"]`);
    $option.attr("checked", true);

    const overlay = new Overlay({
      modifiers: ["dark"],
    });
    overlay.show($content);

    initInput();
    initTextarea();
    initFormBackup();

    $("body").trigger("SETUP_CAPTCHA");
  }
}

function init() {
  const $trigger = $(".js-training");

  $trigger.on("click", (event) => {
    const $button = $(event.currentTarget);

    new Training({
      option: $button.data("training"),
    });

    window.location.hash = "training-" + $button.data("training");
  });

  const hash = window.location.hash;
  console.log(hash);
  if (hash.startsWith("#training-")) {
    new Training({
      option: hash.split("#training-")[1],
    });
  }
}

export { init };
