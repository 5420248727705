import $ from "jquery";

import { getScrollState, hasScrolledClass } from "./body-scrolling";

const BREAKPOINTS = {
  SMALL: "0",
  MEDIUM: "480",
  LARGE: "768",
};

/**
 * get current viewport dimensions (width, height)
 *
 * @return Array [viewPortWidth, viewPortHeight]
 */
const getViewport = () => {
  let viewPortWidth, viewPortHeight;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== "undefined") {
    viewPortWidth = window.innerWidth;
    viewPortHeight = window.innerHeight;
  } else if (
    typeof document.documentElement !== "undefined" &&
    typeof document.documentElement.clientWidth !== "undefined" &&
    document.documentElement.clientWidth !== 0
  ) {
    // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    viewPortWidth = document.documentElement.clientWidth;
    viewPortHeight = document.documentElement.clientHeight;
  } else {
    // older versions of IE
    viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
    viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
  }

  // return dimension
  return {
    width: viewPortWidth,
    height: viewPortHeight,
  };
};

const _limitDuration = (duration) => {
  const MIN_DURATION = 1000;
  const MAX_DURATION = 1000;

  return Math.max(MIN_DURATION, Math.min(MAX_DURATION, duration));
};

const _predictOffset = (next, current) => {
  const state = getScrollState(next, current);
  const isScrolled = hasScrolledClass();
  const isMobile = window.matchMedia("(max-width: 640px)").matches;
  const $subnavigation = $(".js-sticky");

  let offset;

  // scrolled class will be added
  if (state === 1) {
    offset = 50;

    // scrolled class will be removed
  } else if (state === -1) {
    // viewport related header height
    offset = isMobile ? 50 : 100;

    // state remains
  } else {
    // viewport related header height or scrolled state
    offset = isMobile || isScrolled ? 50 : 100;
  }

  // additional offset if sub navigation is fixed
  if ($subnavigation.length && $subnavigation.offset().top - offset < next) {
    offset += 50;
  }

  return offset;
};

const scrollToContent = ($target, instant = false) => {
  if (!$target.length) {
    return;
  }

  const top = $target.length ? $target.offset().top : 0;
  const scrollTop = $(window).scrollTop();

  const velocity = top - scrollTop;
  const delta = Math.abs(velocity);

  const offset = _predictOffset(top, scrollTop);

  if (top - offset !== scrollTop) {
    $("html, body").animate(
      {
        scrollTop: top - offset,
      },
      instant ? 0 : _limitDuration(delta)
    );
  }
};

export { BREAKPOINTS, getViewport, scrollToContent };
